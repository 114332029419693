import type { GatsbyBrowser } from 'gatsby';
import { fixUrl, mailBridgeSupportPath } from '@protonme/algolia/dist/utils/fixUrl';
import type { WindowLocation } from '@reach/router';

export { wrapPageElement } from './src/modules/gatsby/wrapPageElement';

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = () => {
    const { hash } = window.location;

    if (typeof hash === 'string') {
        const targetElement = document.getElementById(hash.substring(1));
        if (targetElement) {
            targetElement.scrollIntoView();
        }

        // It should only scroll to the top of the page in case there is no anchor
        if (!hash.length) {
            window.scrollTo(0, 0);
        }
        return false;
    }
    return true;
};

type Location = WindowLocation<Record<string, string | null>>;

// CAUTION: if you change this also update useAnalyticsBeacon integration
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }) => {
    if ((location as Location)?.state) {
        (location as Location).state.prevPage = prevLocation?.pathname || null;
    }
};

export const onClientEntry: GatsbyBrowser['onClientEntry'] = () => {
    const { href, pathname, search } = window.location;

    if (!href.startsWith('https://proton.me')) {
        if (pathname === '/preview') {
            const params = new URLSearchParams(search);
            const token = params.get('token');
            const documentId = params.get('documentId');

            if (token && documentId) {
                const urlFromToken = new URL(token);
                const releaseId = urlFromToken.pathname.split('/previews/')?.[1];

                window.location.search = `?id=${documentId}${
                    releaseId ? `&release=${releaseId}` : ''
                }`;
            }
        }
    }

    if (mailBridgeSupportPath.test(pathname)) {
        window.location.pathname = fixUrl(pathname);
    }
};

// eslint-disable-next-line
console.log('listen for beforeinstallprompt from gatsby-browser');
window.addEventListener('beforeinstallprompt', (event) => {
    // eslint-disable-next-line
    console.log('beforeinstallprompt event in gatsby-browser', event);
});
